<template>
  <div>
    <div  v-if="landing.profi">
      <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Management settings</label>
      <div
          style="display: flex; align-items: center; justify-content: space-between"
      >
        <label class="label" style="flex: 3"></label>
        <v-btn
            @click="openAddUser"
            class="popup__link popup__open object__submit btn btn_primary"
            style="flex: 1; margin-right: 15px"
            color="primary"
            outlined
            small
            solo
        >
          Add manager
        </v-btn>
        <v-btn
            @click="openRegisterUser"
            class="popup__link popup__open object__submit btn btn_primary"
            style="flex: 1"
            color="primary"
            outlined
            small
            solo
        >
          Create manager
        </v-btn>
      </div>
      <div style="margin: 15px 0">
        <v-data-table
            class="table"
            :headers="headers"
            :items="userList"
            hide-default-footer
        >
          <template v-slot:body="{ items }">
            <tbody v-if="items.length">
            <tr
                v-for="(item, index) in userList"
                :key="item.email"
            >
              <td class="d-block d-sm-table-cell">
                <v-layout justify-left>{{ item.name }}</v-layout>
              </td>
              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>{{ item.email }}</v-layout>
              </td>
              <td class="d-block d-sm-table-cell">
                <v-layout justify-center>{{ item.is_manager&&item.is_reception?'M+R':'' }} {{ item.is_manager&&!item.is_reception?'M':'' }} {{ !item.is_manager&&item.is_reception?'R':'' }}</v-layout>
              </td>
              <td class="d-block d-sm-table-cell" style="cursor: pointer">
                <v-layout justify-center
                ><v-icon @click="deleteManager(item.id, index)"
                >mdi-delete</v-icon
                ></v-layout
                >
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td class="d-block d-sm-table-cell" colspan="4" style="text-align: center">
                You don`t have managers here, create them
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>

        <!--      <div style="color: #7f8385; margin-top: 15px; font-size: 14px">-->
        <!--        M - the user is connected in this project as a manager and has the ability to change any settings in the constructor <br>-->

        <!--        R - the user can only enter the dashboard of orders and manage them using the link <a href="https://d.guesteco.com">d.guesteco.com</a> <br>-->

        <!--        M+R - the user has full rights, including access to the constructor and to the orders dashboard (<a href="https://d.guesteco.com">d.guesteco.com</a>)-->
        <!--      </div>-->
      </div>
    </div>
    <div style="display: flex;padding:20px 0;" v-if="!landing.profi">
      <v-icon color="#cccccc" size="60px" style="margin-right: 20px;">mdi-lock</v-icon>
      <h1 class="constructor__heading" style="font-weight: normal;font-size: 16px;">This feature is available on the Pro plans.
        <br>To activate the tariff <a @click="billing">click here</a></h1>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogChange"
      max-width="500px"
      id="popup-registration1"
      class="popup-registration popup mfp-hide"
    >
      <div class="popup__form popup__content">
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="dialogChange = false"
          >mdi-close</v-icon
        >
        <v-form
          class="form"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
          ref="form_add_user"
          @submit="addUser"
          v-model="valid_change"
          novalidate
        >
          <h2 class="popup__title" style="margin-bottom: 15px;">Adding an existing user</h2>
          <p style="color:#999;font-size:12px;">Enter the email address of a previously created user on our platform.</p>
<!--            <div-->
<!--                class="form__control"-->
<!--                style="display: flex; justify-content:center;flex-direction:column;margin-bottom: 15px"-->
<!--            >-->
<!--              <div style="display: flex;align-items:center;margin-bottom:10px;">-->
<!--                <label class="label" style="margin: 0 10px 0 0;width:150px;position:relative;top:5px;">Manager</label>-->
<!--                <v-switch color="#6ab64a"-->
<!--                    hide-details-->
<!--                    :value="!!changedUser.is_manager"-->
<!--                    @click="changeManager(changedUser)"-->
<!--                ></v-switch>-->
<!--              </div>-->
<!--              <p style="color:#999;font-size:12px;">The manager role gives access to the constructor.</p>-->
<!--            </div>-->
<!--            <div-->
<!--                class="form__control"-->
<!--                style="display: flex; justify-content:center;flex-direction:column;margin-bottom: 15px"-->
<!--            >-->
<!--              <div style="display: flex;align-items:center;margin-bottom:10px;">-->
<!--                <label class="label" style="margin: 0 10px 0 0;width:150px;position:relative;top:5px;">Reception</label>-->
<!--                <v-switch color="#6ab64a"-->
<!--                    hide-details-->
<!--                    :value="!!changedUser.is_reception"-->
<!--                    @click="changeReception(changedUser)"-->
<!--                ></v-switch>-->
<!--              </div>-->
<!--              <p style="color:#999;font-size:12px;">The reception role gives access to <a href="https://d.guesteco.com">d.guesteco.com</a>.</p>-->
<!--            </div>-->
          <div class="form__item form__item_error">
            <div class="form__control">
              <v-text-field
                solo
                outlined
                flat
                type="text"
                placeholder="User email"
                v-model="changedUser.email"
                :rules="emailRules"
                required
                validate-on-blur
              ></v-text-field>
            </div>
          </div>
          <div
            class="v-messages theme--light error--text"
            v-show="changeError"
            style="margin-bottom: 20px"
            role="alert"
          >
            <div class="v-messages__wrapper">
              <div class="v-messages__message" style="font-size: 14px">
                {{ changeError }}
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
          >
            <v-btn color="primary"
                   outlined
                   small type="submit" class="btn_form">
              Confirm
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialog"
      max-width="500px"
      id="popup-registration"
      class="popup-registration popup mfp-hide"
    >
      <div class="popup__form popup__content">
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="dialog = false"
          >mdi-close</v-icon
        >
        <v-form
          class="form"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
          ref="form_register"
          @submit="registerForm"
          v-model="valid_register"
          novalidate
        >
          <h2 class="popup__title">Register user</h2>
          <div class="form__item form__item_error" style="width: 80%">
<!--            <div-->
<!--              class="form__control"-->
<!--              style="display: flex; justify-content:center;flex-direction:column;margin-bottom: 15px"-->
<!--            >-->
<!--             <div style="display: flex;align-items:center;margin-bottom:10px;">-->
<!--               <label class="label" style="margin: 0 10px 0 0;width:150px;position:relative;top:5px;">Manager</label>-->
<!--               <v-switch color="#6ab64a"-->
<!--                   hide-details-->
<!--                   :input-value="default_manager.is_manager"-->
<!--                   @click="changeManager(default_manager)"-->
<!--               ></v-switch>-->
<!--             </div>-->
<!--              <p style="color:#999;font-size:12px;">The manager role gives access to the constructor.</p>-->
<!--            </div>-->
<!--            <div-->
<!--                class="form__control"-->
<!--                style="display: flex; justify-content:center;flex-direction:column;margin-bottom: 15px"-->
<!--            >-->
<!--              <div style="display: flex;align-items:center;margin-bottom:10px;">-->
<!--                <label class="label" style="margin: 0 10px 0 0;width:150px;position:relative;top:5px;">Reception</label>-->
<!--                <v-switch color="#6ab64a"-->
<!--                    hide-details-->
<!--                    :input-value="default_manager.is_reception"-->
<!--                    @click="changeReception(default_manager)"-->
<!--                ></v-switch>-->
<!--              </div>-->
<!--              <p style="color:#999;font-size:12px;">The reception role gives access to <a href="https://d.guesteco.com">d.guesteco.com</a>.</p>-->
<!--            </div>-->
            <div class="form__control">
              <v-text-field
                solo
                outlined
                flat
                type="text"
                placeholder="Manager name"
                v-model="default_manager.name"
                :rules="nameRules"
                required
                validate-on-blur
              ></v-text-field>
            </div>
            <div class="form__control">
              <v-text-field
                solo
                outlined
                flat
                type="email"
                placeholder="E-mail"
                v-model="default_manager.email"
                :rules="emailRules"
                :error-messages="userExist"
                :error="userExistError"
                required
                validate-on-blur
              ></v-text-field>
            </div>
            <div class="form__control">
              <v-text-field
                solo
                outlined
                flat
                type="password"
                :placeholder="$t('landing.popup[\'Пароль\']')"
                v-model="default_manager.password"
                :rules="passwordRules"
                required
                validate-on-blur
              ></v-text-field>
              <!-- <input type="password" class="input" placeholder="Пароль" v-model="register.password" required> -->
            </div>
          </div>

          <div class="form__actions">
            <label class="checkbox">
              <input
                style="margin-right: 15px"
                class="checkbox__input"
                type="checkbox"
                v-model="is_agree"
              />
              <span
                class="checkbox__text"
                v-html="$t('landing.popup.policy')"
              ></span>
            </label>
          </div>
          <div
            class="v-messages theme--light error--text"
            v-show="register_error"
            style="margin: 20px 0"
            role="alert"
          >
            <div class="v-messages__wrapper">
              <div class="v-messages__message" style="font-size: 16px">
                {{ register_error }}
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
          >
            <v-btn color="primary"
                   outlined
                   small type="submit" class="btn_form">
              {{ $t('landing.popup["Зарегистрироваться"]') }}
            </v-btn>
          </div>
          <!-- <p class="popup__or">или</p> -->
          <!-- <a href="#" class="btn_facebook">Продолжить через Facebook</a> -->
        </v-form>
      </div>
    </v-dialog>
    <PopupDelete
      ref="PopupDelete"
      @confirm="deleteManagerSuccess"
      :title="`Are you sure about delete ${deleteIndex||deleteIndex===0?userList[deleteIndex].name:''} from your project?`"
    />
    <PopupSuccess ref="PopupSuccess" :title="successMessage"/>
    <PopupTariff ref="PopupTariff"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserService from "../../../services/user.service"
import PopupSuccess from "@/components/Popup/PopupSuccess";
export default {
  name: "Management",
  components: {
    PopupSuccess,
    PopupDelete:() => import("../../Popup/PopupDelete"),
    PopupTariff: () => import("../../Popup/PopupTariff"),
  },
  data() {
    return {
      userList:[],
      dialog: false,
      dialogChange: false,
      is_agree: true,
      selectedStatus: "reception",
      statuses: ["reception", "manager"],
      register_error: null,
      valid_register: true,
      valid_change: true,
      deleteId: null,
      deleteIndex: null,
      userExist:"",
      userExistError:false,
      nameRules: [(v) => !!v || this.$t('landing.valid["Имя обязателено"]')],
      passwordRules: [
        (v) => !!v || this.$t('landing.valid["Пароль обязателен"]'),
        (v) =>
          (v && v.length >= 6) ||
          this.$t(
            'landing.valid["Пароль должен содержать не менее 6 символов"]'
          ),
        (v) =>
          (v && v.length < 30) ||
          this.$t("form['Пароль должен содержать не более 30 символов']"),
      ],
      passwordConfirmRules: [
        (v) => !!v || this.$t("form['Укажите пароль']"),
        (v) =>
          v === this.restore.password || this.$t("form['Пароли не совпадают']"),
        (v) =>
          (v && v.length >= 6) ||
          this.$t("form['Пароль должен содержать не менее 6 символов']"),
        (v) =>
          (v && v.length < 30) ||
          this.$t("form['Пароль должен содержать не более 30 символов']"),
      ],
      emailRules: [
        (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t('landing.valid["E-mail должен быть валидным"]'),
      ],
      default_manager: {
        id: null,
        name: null,
        email: null,
        password: null,
        is_manager: 1,
        is_reception: 0,
      },
      headers: [
        { text: "Name", align: "left", sortable: true },
        { text: "Email", align: "center", sortable: true },
        { text: "Role", align: "center", sortable: true },
        { text: "Options", align: "center", sortable: true },
      ],
      changedUser: {
        is_manager: 1,
        email: "",
        is_reception: 0,
      },
      changeError: "",
      successMessage:''
    };
  },
  methods: {
    billing(){
      let obj = this.$store.state.objects.list.find(x => +x.id === +this.$route.params.id);
      this.$refs.PopupTariff.open(obj);
    },
    openRegisterUser(){
      this.default_manager = {
        id: null,
        name: null,
        email: null,
        password: null,
        is_manager: 1,
        is_reception: 0,
      };
      this.dialog = true
    },
    openAddUser() {
      this.changedUser = {
        is_manager: 1,
        email: "",
        is_reception: 0,
      };
      this.dialogChange = true;
    },
    async addUser(e) {
      this.changeError = "";
      e.preventDefault();
      if(this.changedUser.email === this.$store.state.profile.email){
        this.changeError = "You can not add self to management";
        return;
      }
      if (!this.changedUser.is_reception && !this.changedUser.is_manager) {
        this.changeError = "User one role required";
        return;
      }
      if (this.$refs.form_add_user.validate()) {
        const payload = {
          hotel_id: +this.$route.params.id,
          email: this.changedUser.email,
          is_reception: this.changedUser.is_reception,
          is_manager: this.changedUser.is_manager,
        };
        const data = await UserService.changeRole(payload);
        const candidate = this.userList.find(user => user.id === data.id);
        if(candidate){
          candidate.is_manager = data.is_manager;
          candidate.is_reception = data.is_reception;
          this.dialogChange = false;
        }else{
          if (data.id) {
            this.userList.push(data);
            this.dialogChange = false;
          }else{
            this.changeError = data;
          }
        }
      }
    },
    changeManager(user) {
      if (user.is_manager) {
        user.is_manager = 0;
        user.is_reception = 1;
      } else {
        user.is_manager = 1;
      }
    },
    changeReception(user) {
      if (user.is_reception) {
        user.is_manager = 1;
        user.is_reception = 0;
      } else {
        user.is_reception = 1;
      }
    },
    async changeManagerStatus(item) {
      if (this.selectedStatus === "reception") {
        this.default_manager.is_manager = 0;
        this.default_manager.is_reception = 1;
      }
      if (this.selectedStatus === "manager") {
        this.default_manager.is_manager = 1;
        this.default_manager.is_reception = 0;
      }
    },
    async deleteManagerSuccess() {
      await UserService.deleteUserFromHotel(this.deleteId);
      this.userList.splice(this.deleteIndex, 1);
      this.successMessage = 'User disabled from your project!';
      this.$refs.PopupSuccess.open();
      this.deleteId = null;
      this.deleteIndex = null;
    },
    async deleteManager(id, index) {
      this.deleteId = id;
      this.deleteIndex = index;
      this.$refs.PopupDelete.open();
    },
    async registerForm(e) {
      e.preventDefault();
      this.$refs.form_register.validate();
      this.default_manager.lang = this.$route.params.lang || "en";
      this.default_manager.hotels_id = [+this.$route.params.id];
      if(!this.default_manager.is_manager&&!this.default_manager.is_reception){
        this.register_error = 'Choose expect one role';
      }
      else if (!this.is_agree) {
        this.register_error = this.$t(
          'landing.valid["Необходимо принять условия использования и пользовательское соглашение"]'
        );
      } else {
        if (this.$refs.form_register.validate()) {
          this.userExistError = false;
          this.register_error = null;
          await this.$store
            .dispatch("register", this.default_manager)
            .then((response) => {
              this.register_error = null;
              this.dialog = false;
              this.default_manager.id = response.data.id;
              this.userList.push(this.default_manager);
            })
            .catch(() => {
              this.userExistError = true;
              this.userExist = 'User with this email already exist!'
            });
        }
      }
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState(['statistics']),
  },
  async mounted(){
    this.userList = await UserService.getUserList(this.$route.params.id);
  }
};
</script>

<style scoped></style>
